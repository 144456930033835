// @ts-nocheck

import {
  DrawPolygonMode,
  DrawPointMode,
} from '@nebula.gl/edit-modes';
import { round } from 'lodash';
import React, {
  useState,
  useEffect,
  useContext,
  createContext,
} from 'react';
import {
  Editor,
  EditingMode,
  RENDER_STATE,
} from 'react-map-gl-draw';

import { getColourForSiteType } from 'common-ui/Sites';
import { useFlag, FLAG_POSITIONS } from 'components/Common/Flag';
import {
  dropPinToastID,
  DrawFenceToastID,
  DropPinToast,
  DrawFenceToast,

} from 'pages/Sites/components/Toasts';


export const selectedLocationTypes = {
  ADDRESS: 'address',
  POINT: 'point',
};

// POLYGON is for actually defining the site's geofence
// CIRCLE is the regular geofence defined by a radius
// TODO POLYGON edit mode forces the user to draw polygons unless they're
// focused on the location input field
export const editModes = {
  POLYGON: 'polygon',
  CIRCLE: 'circle',
};

// https://en.wikipedia.org/wiki/Decimal_degrees#Precision
// accurate within 11cm
const COORDINATE_PRECISION = 6;

// Never use this directly! See MapEditContextProvider

const MapEditContext = createContext();

export const useEditableMap = () => {
  const editMapContext = useContext(MapEditContext);
  if (!editMapContext) {
    throw new Error('must use editable map context with MapEditContextProvider');
  }
  return editMapContext;
};

export const roundLatLon = (location: any) => {
  const newLoc = { ...location };
  if (newLoc.lng) {
    newLoc.lng = round(newLoc.lng, COORDINATE_PRECISION);
  }
  if (newLoc.lat) {
    newLoc.lat = round(newLoc.lat, COORDINATE_PRECISION);
  }
  return newLoc;
};

// Feature geoJSON for reference
// Always include the 'properties' field, the deck.gl
// lib expects it to be there even if it's an empty object
const initialPolygonFeature = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Polygon',
    // Yes, believe it or not this is an array of an array of lat/lon pair arrays
    coordinates: [
      [],
    ],
  },
};

const initialPointFeature = {
  type: 'Feature',
  properties: {},
  geometry: {
    type: 'Point',
    // For point features, the coordinate property is just a list of lat/lon
    coordinates: [],
  },
};

// This is the context provider in charge of setting the default context object
// for editing objects on the map. This allow us to share stateful data
// with different components as long as they all have this Provider as a parent
// component somewhere up in the component hierarchy
export const MapEditContextProvider = ({
  children
}: any) => {
  const [isEditingMap, setIsEditingMap] = useState(false);
  // SelectedLocation is a special object referring to the selected location
  // Modify it to change the focused location on the map
  // If the map has forceFocusToPoint prop set to this
  const [selectedLocation, setSelectedLocation] = useState(null);
  // geoPoints is a list of lat/lon coordinate pair arrays
  // You can call setGeoPoints to modify the displayed feature on the edit layer
  const [geoPoints, setGeoPoints] = useState([]);
  const [editMode, setEditMode] = useState(editModes.CIRCLE);
  const { addFlag, removeFlag } = useFlag();
  const stopEditingMap = () => {
    setSelectedLocation(null);
    setGeoPoints([]);
    setIsEditingMap(false);
    removeFlag(dropPinToastID);
    removeFlag(DrawFenceToastID);
  };
  const setPolygonEditMode = () => {
    setEditMode(editModes.POLYGON);
    addFlag(DrawFenceToastID, DrawFenceToast, FLAG_POSITIONS.topCenter, 0);
    removeFlag(dropPinToastID);
  };
  const setCircleEditMode = () => {
    setEditMode(editModes.CIRCLE);
    addFlag(dropPinToastID, DropPinToast, FLAG_POSITIONS.topCenter, 0);
    removeFlag(DrawFenceToastID);
  };

  return (

    <MapEditContext.Provider
      value={{
        isEditingMap,
        setIsEditingMap,
        selectedLocation,
        setSelectedLocation,
        stopEditingMap,
        editMode,
        setPolygonEditMode,
        setCircleEditMode,
        geoPoints,
        setGeoPoints,
      }}
    >
      {children}
    </MapEditContext.Provider>
  );
};

// This util hook allows you to tie the map selected location context
// to a form value setter e.g. with Formik's setValues function
export const useSitesMapSelect = (setValues: any, values: any) => {

  const { selectedLocation, setSelectedLocation } = useEditableMap();
  const { radius, siteType } = values;
  useEffect(() => {
    if (selectedLocation && selectedLocation.lat && selectedLocation.lng) {
      let newValues = { ...values, pointLocation: { latitude: selectedLocation.lat, longitude: selectedLocation.lng } };
      if (selectedLocation.type !== selectedLocationTypes.ADDRESS) {
        const newLoc = roundLatLon(selectedLocation);
        newValues = { ...newValues, location: `${newLoc.lat}, ${newLoc.lng}` };
      }
      setValues({ ...values, ...newValues });
    }
  }, [selectedLocation]);
  useEffect(() => {
    if (radius || siteType) {
      setSelectedLocation({ ...selectedLocation, radius, siteType });
    }
  }, [radius, siteType]);
};

export const useSitesGeoPointsSelect = (setValues: any, values: any) => {
  const { geoPoints, editMode } = useEditableMap();
  useEffect(() => {
    if (geoPoints.length > 0) {
      setValues({ ...values, geoPoints });
    }
  }, [geoPoints, editMode]);
};

const polygonMode = new DrawPolygonMode();
const pointMode = new DrawPointMode();
const editingMode = new EditingMode();

// MapEditLayer provides an editable layer that can be attached to any ReactMapGL component
// Depends on a MapEditContext to be available somewhere up in the component tree
export const MapEditLayer = () => {
  const {
    isEditingMap,
    editMode,
    geoPoints,
    setGeoPoints,

    selectedLocation,

    setSelectedLocation,
  } = useEditableMap();
  const [polygonFeature, setPolygonFeature] = useState(initialPolygonFeature);
  const [pointFeature, setPointFeature] = useState(initialPointFeature);
  const [displayedFeatures, setDisplayedFeatures] = useState([]);
  const { removeFlag } = useFlag();

  useEffect(() => {
    setPolygonFeature(prevFeature => ({
      ...prevFeature,
      geometry: {
        type: 'Polygon',
        coordinates: [geoPoints]
      },
    }));
  }, [geoPoints]);

  useEffect(() => {
    if (selectedLocation && selectedLocation.lat) {
      setPointFeature({
        ...initialPointFeature,
        geometry: {
          type: 'Point',

          coordinates: [selectedLocation.lng, selectedLocation.lat],
        },
      });
    }
  }, [selectedLocation]);

  // Do not actually display point feature, let that be handled by the sitemarkers
  // TODO We should probably move all edit marker logic over to this layer instead
  // we would need to rework the fitBounds logic though, since it determines viewport
  // based on the list of markers (including the edit marker) provided to it
  useEffect(() => {
    let newFeatures: any = [];
    if (!isEditingMap) {
      setDisplayedFeatures([]);
      return;
    }
    if (editMode === editModes.POLYGON) {
      newFeatures = [...newFeatures, polygonFeature];
    }
    if (pointFeature.geometry.coordinates.length >= 2) {
      newFeatures = [...newFeatures, pointFeature];
    }
    setDisplayedFeatures(newFeatures);
  }, [isEditingMap, editMode, pointFeature, polygonFeature]);

  const getEditHandleStyle = ({
    state
  }: any) => {
    const color = getColourForSiteType(selectedLocation ? selectedLocation.siteType : null);
    switch (state) {
      case RENDER_STATE.SELECTED:
      case RENDER_STATE.HOVERED:
      case RENDER_STATE.UNCOMMITTED:
        return {
          fill: '#FFFFFF',
          fillOpacity: 1,
          stroke: color,
          strokeWidth: 2,
          r: 6,
        };
      default:
        return {
          fill: color,
          fillOpacity: 1,
          stroke: color,
          strokeWidth: 1,
          r: 5,
        };
    }
  };

  const getFeatureStyle = ({
    state
  }: any) => {
    const color = getColourForSiteType(selectedLocation ? selectedLocation.siteType : null);
    switch (state) {
      case RENDER_STATE.SELECTED:
      case RENDER_STATE.UNCOMMITTED:
      case RENDER_STATE.CLOSING:
        return {
          fill: color,
          fillOpacity: 0.1,
          stroke: color,
          strokeWidth: 2,
          pointerEvents: 'none',
        };
      case RENDER_STATE.HOVERED:
      default:
        return {
          fill: color,
          fillOpacity: 0.2,
          pointerEvents: 'none',
        };
    }
  };

  return (

    <Editor
      style={{ zIndex: 10 }}
      clickRadius={12}
      onUpdate={(e: any) => {
        if (e.editType === 'addFeature') {
          // Get the new feature from the editContext property
          const newFeatureIndex = e.editContext.featureIndexes[0];
          const newFeature = e.data[newFeatureIndex];
          if (editMode === editModes.POLYGON) {
            setGeoPoints(newFeature.geometry.coordinates[0]);
            removeFlag(DrawFenceToastID);
          } else if (editMode === editModes.CIRCLE && newFeature.geometry.type === 'Point') {
            const pointLocation = { lng: newFeature.geometry.coordinates[0], lat: newFeature.geometry.coordinates[1] };
            setSelectedLocation((prev: any) => ({
              ...prev,
              ...pointLocation,
              type: selectedLocationTypes.POINT
            }));
            removeFlag(dropPinToastID);
          }
        } else if (e.editType === 'addTentativePosition') {
          removeFlag(DrawFenceToastID);
        }
      }}
      editHandleShape="circle"

      mode={isEditingMap
        ? (editMode === editModes.POLYGON && polygonMode)
          || (editMode === editModes.CIRCLE && pointMode)
        : editingMode
      }
      features={displayedFeatures}
      featureStyle={getFeatureStyle}
      editHandleStyle={getEditHandleStyle}
    />
  );
};
