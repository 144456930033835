// @ts-nocheck
import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';

import { SiteIcon } from 'common-ui/Sites';
import { useOutsideClickHandler } from 'utils/Hooks';
import googleLogo from './powered_by_google_on_white.png';
import styles from './styles.module.scss';


const isLatLon = (locationString: any) => {
  if (!locationString || locationString === '') return false;
  let vals = locationString.split('/');
  if (vals.length === 2 && Number.isFinite(parseFloat(vals[0])) && Number.isFinite(parseFloat(vals[1]))) {
    return true;
  }
  vals = locationString.split(',');
  if (vals.length === 2 && Number.isFinite(parseFloat(vals[0])) && Number.isFinite(parseFloat(vals[1]))) {
    return true;
  }
  return false;
};

const getOptionIconForType = (type: any, siteType: any, input = false) => {
  switch (type) {
    case 'site':
      return (

        <SiteIcon
          type={siteType}

          className={classNames(styles.addressTypeIcon, {
            [styles.inputIcon]: input,
          })}
        />
      );
    case 'autocomplete_address':
    default:
      return null;
  }
};

const getOptionValue = (option: any) => {
  switch (option.type) {
    case 'site':
      return option.label;
    case 'autocomplete_address':
    default:
      return option.value;
  }
};

const AddressDropdown = ({
  onSelect,
  options,
  hoveredIndex,
  setHoveredIndex,
  addressesEnabled,
  ...props
}: any) => {
  const [maxHeight, setMaxHeight] = useState('auto');
  const ref = useRef();

  // Gather all option components first
  // since we need to maintain index between Sites and Address types

  const optionComponents = options.map((o, i) => ({
    type: o.type,


    comp: <AddressOption
      key={`${o.type}-${o.type === 'site' ? o.id : o.value}`}
      option={o}
      index={i}
      {...{
        hoveredIndex,
        setHoveredIndex,
        onSelect,
      }}
    />
  }));

  useEffect(() => {
    if (ref.current) {
      // Limit the height to the bottom of the viewport

      const rect = ref.current.getBoundingClientRect();
      const maxPossibleHeight = window.innerHeight - rect.top - 5;

      if (maxPossibleHeight > 0) {
        setMaxHeight(`${maxPossibleHeight}px`);
      }
    }
  }, []);

  return (

    <div ref={ref} className={styles.autocompleteMenu} style={{ maxHeight }}>
      {optionComponents.filter((c: any) => c.type === 'site_create').map((c: any) => c.comp)}
      {optionComponents.filter((c: any) => c.type === 'site').map((c: any) => c.comp)}
      {optionComponents.filter((c: any) => c.type === 'autocomplete_address').map((c: any) => c.comp)}
      {addressesEnabled && (

        <div className={styles.googleLogo}>
          <img src={googleLogo} alt="Powered by Google" />
        </div>
      )}
    </div>
  );
};

const AddressOption = ({
  option,
  index,
  hoveredIndex,
  setHoveredIndex,
  onSelect
}: any) => (

  <div
    role="button"
    tabIndex={0}

    className={classNames(styles.option, {
      [styles.hovered]: index === hoveredIndex,
      [styles.site]: option.type === 'site',
    })}
    onMouseDown={(e) => {
      e.preventDefault(); // Prevent input from losing focus on click
      onSelect(option);
    }}
    onMouseEnter={() => setHoveredIndex(index)}
    onMouseLeave={() => setHoveredIndex(-1)}

    value={option.value}
  >
    {getOptionIconForType(option.type, option.siteType)}
    {getOptionValue(option)}
  </div>
);

// This is a generic address autocompletion dropdown that allows typed selected values
// that will change the way values display (displayValue)
// e.g. for Sites we place a special marker indicating the selected address is a site
const SitesAddressInput = ({
  formik,
  displayValue,
  label,
  required,
  name,
  onSelectOption,
  selectedValue,
  setSelectedValue,
  suggestions,
  onKeyDown,
  onChange,
  embedded,
  onSiteEdit,
  disabled,
  enableCreateSite,
  enableFreeformText,
  addressesEnabled,
  ...inputProps
}: any) => {
  const inputRef = useRef();
  const [autocompleteHidden, setAutocompleteHidden] = useState(true);
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  useOutsideClickHandler(inputRef, () => {
    setAutocompleteHidden(true);
    onSiteEdit(false);
  }, []);
  const inputValue = displayValue || formik.values[name];

  const [siteCreateOption, setSiteCreateOption] = useState(null);
  useEffect(() => {
    if (inputValue && enableCreateSite) {
      setSiteCreateOption({

        type: 'site_create',
        value: `Create site "${inputValue}"`,
        label: 'Creating site',
        name: inputValue,
      });
    } else {
      setSiteCreateOption(null);
    }
  }, [inputValue]);

  const combinedSuggestions = siteCreateOption ? [...suggestions, siteCreateOption] : suggestions;
  return (
    <div
      role="listbox"
      tabIndex={0}

      className={classNames(styles.addressField, { [styles.embedded]: embedded })}
      onKeyDown={(e) => {
        switch (e.key) {
          case 'ArrowDown':
            e.preventDefault();
            setHoveredIndex(combinedSuggestions && hoveredIndex < combinedSuggestions.length - 1 ? hoveredIndex + 1 : 0);
            break;
          case 'ArrowUp':
            e.preventDefault();
            setHoveredIndex(hoveredIndex > 0 ? hoveredIndex - 1 : combinedSuggestions.length - 1 || 0);
            break;
          case 'Enter':
            e.preventDefault();
            // if we don't allow freeform text, and this doesn't look like lat/lon, clear it
            if (!isLatLon(formik.values[name]) && !enableFreeformText) formik.setFieldValue(name, '');
            setAutocompleteHidden(true);
            onSiteEdit(false);
            if (!autocompleteHidden) {
              if (hoveredIndex >= 0 && combinedSuggestions[hoveredIndex]) {
                onSelectOption(combinedSuggestions[hoveredIndex]);
              } else if (combinedSuggestions.length > 0) {
                // No explicitly selected option, but we can guess it's the first one
                // If freeFormText is enabled, match entire site name before we select it
                // in case the user wants to enter text as a site
                if (!enableFreeformText) {
                  onSelectOption(combinedSuggestions[0]);
                } else {
                  const selectedOption = combinedSuggestions.find((o: any) => o.value === formik.values[name]);
                  if (selectedOption) onSelectOption(selectedOption);
                }
              }
            }
            break;
          case 'Escape':
            e.preventDefault();
            if (!enableFreeformText) formik.setFieldValue(name, '');
            setAutocompleteHidden(true);
            onSiteEdit(false);
            break;
          default:
            break;
        }
      }}
    >
      {selectedValue && getOptionIconForType(selectedValue.type, selectedValue.siteType, true)}

      <input
        ref={inputRef}

        className={classNames(styles.addressInputField, {
          [styles.embedded]: embedded,
          [styles.focused]: !autocompleteHidden,
          [styles.autocompleted]: selectedValue && selectedValue.type === 'site',
          [styles.setcolor]: embedded,
          [styles.disabled]: !!disabled,
        })}
        autoComplete="off"
        value={inputValue}
        onClick={() => {
          setAutocompleteHidden(false);
          onSiteEdit(true);
        }}
        onChange={(e) => {
          setAutocompleteHidden(false);
          onChange(e);
          onSiteEdit(true);
        }}
        onBlur={() => {
          formik.setFieldTouched(name);
          // If not lat/lon, and we don't accept freeform text
          // clear it
          if (!selectedValue && !enableFreeformText && !isLatLon(formik.values[name])) {
            formik.setFieldValue(name, '');
          }
        }}
        disabled={disabled}
        {...{
          onKeyDown,
          ...inputProps,
        }}
      />
      {!autocompleteHidden && combinedSuggestions && combinedSuggestions.length > 0
          && (
            <AddressDropdown
              {...{
                hoveredIndex,
                setHoveredIndex,
              }}
              addressesEnabled={addressesEnabled}
              options={combinedSuggestions}
              onSelect={onSelectOption}
            />
          )
      }
    </div>
  );
};

SitesAddressInput.defaultProps = {
  onSiteEdit: () => {},
};

export default SitesAddressInput;
