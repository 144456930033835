// @ts-nocheck
import { Form, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import ID from 'common-ui/ConsistentIDs';
import {
  FormikTextInput,
  FormikDropdown,
  FormikRadio,
  AddressAutocompleteInput,

} from 'components/Common/Form';

import { useGeocoderService } from 'components/Common/Form/AddressInput/GoogleAutocomplete';
import {
  selectedLocationTypes,
  useEditableMap,
  useSitesMapSelect,
  useSitesGeoPointsSelect,

} from 'components/Common/Map/utils/useEditableMap';

import { GeofenceRadiusTextInput } from 'pages/Sites/components/Form/BaseForm';
import { siteTypeOptions, geofenceTypes } from 'pages/Sites/utils/site.utils';
import type { intlShape } from 'react-intl';


import { injectIntlHOC } from 'utils/HOC';

type CreateSiteModalFormProps = {

  intl: intlShape,
};

const CreateSiteModalForm = ({ intl }: CreateSiteModalFormProps) => {
  const { values, setValues } = useFormikContext<any>();
  const { formatMessage } = intl;
  useSitesMapSelect(setValues, values);
  useSitesGeoPointsSelect(setValues, values);
  const getGeocode = useGeocoderService();
  const { setSelectedLocation, setPolygonEditMode, setCircleEditMode } = useEditableMap();

  const { location } = values;
  // Default on creation is circle type geofence
  useEffect(() => {
    setCircleEditMode();
  }, []);

  return (

    <Form>

      <FormikTextInput
        name="name"
        label={formatMessage({
          id: 'sites.add.name_label',
          defaultMessage: 'Name',
        })}
        required
        placeholder={formatMessage({
          id: 'sites.add.name_placeholder',
          defaultMessage: 'ex. Saskatoon Asphalt',
        })}
      />


      <AddressAutocompleteInput
        id={ID.create(ID.Areas.General, ID.Types.Input, ID.Labels.SiteCreateAddress)}
        name="location"
        label={formatMessage({
          id: 'sites.add.location_label',
          defaultMessage: 'Location (Address or Lat,Long)',
        })}
        required
        placeholder={
          formatMessage({
            id: 'sites.add.location_placeholder',
            defaultMessage: 'ex. 52.1332, 106.6700',
          })
        }
        onAddressSelect={(o: any) => {
          getGeocode(o.value, (details: any) => {
            const resolvedDetails = details[0]; // First result is probably good enough
            if (resolvedDetails) {
              setSelectedLocation((prev: any) => ({
                ...prev,
                lng: resolvedDetails.geometry.location.lng(),
                lat: resolvedDetails.geometry.location.lat(),
                type: selectedLocationTypes.ADDRESS
              }));
            }
          });
        }}
        hint="If street address is unknown, click on the map to drop a pin"
      />


      <FormikDropdown name="siteType" label="Site Type" options={siteTypeOptions} />


      <FormikRadio
        required
        label="Geofence"
        name="geofenceType"
        disabled={!location}
        onChange={(value: any) => {
          if (value && value === geofenceTypes.CIRCLE) {
            setCircleEditMode();
          } else if (value && value === geofenceTypes.POLYGON) {
            setPolygonEditMode();
          }
        }}
        options={[
          {
            label: 'Circle',
            value: geofenceTypes.CIRCLE,
            children: <GeofenceRadiusTextInput {...{ intl }} />,
          },
          {
            label: 'Draw Geofence',
            value: geofenceTypes.POLYGON,
          },
        ]}
      />
    </Form>
  );
};

export default injectIntlHOC(CreateSiteModalForm);
