// @ts-nocheck

import { useQuery } from '@apollo/client';
import { connect } from 'formik';
import React, { useState, useMemo } from 'react';

import { usePermissions } from 'common-ui/Hooks';




import FormikSitesAddressInput from 'components/Common/Form/Formik/FormikSitesAddressInput';

import { MapEditContextProvider } from 'components/Common/Map/utils/useEditableMap';
import CreateSiteModal from 'pages/Projects/components/CreateSiteModal';
import { SITES_QUERY } from 'pages/Sites/graphql';
import { usePreselectValue, useInputFilteredOptions, useCompanyContext } from 'utils/Hooks';

const getSiteIDField = (name: any) => {
  switch (name) {
    case 'pickUp':
    case 'dropOff':
    default:
      return `${name}SiteID`;
  }
};

const getSiteNameField = (name: any) => {
  switch (name) {
    case 'pickUp':
    case 'dropOff':
    default:
      return `${name}SiteName`;
  }
};

const getSiteCoordinateString = (coordinates: any) => {
  if (!coordinates || !coordinates.length) return '';
  return `${coordinates[0].latitude}, ${coordinates[0].longitude}`;
};

const useSiteSuggestions = (companyID: any) => {
  const { ViewAllSites, ViewOwnSites } = usePermissions(companyID);
  const noSitePermissions = !ViewAllSites && !ViewOwnSites;
  // Since this sites query has filters, apollo doesn't know how to update the cache of this
  // sites query when we add a new site, so we have to force network-only
  const { loading, error, data } = useQuery(
    SITES_QUERY,
    {
      variables: {
        companyID,
        filters: [
          {
            name: 'status',
            operation: 'neq',
            value: 'inactive',
          },
        ],
      },
      skip: !companyID || companyID === '0' || noSitePermissions,
      fetchPolicy: 'network-only',
    },
  );

  // If the companyID or the site data changed, save it to state
  return useMemo(() => (
    (!loading && !error && data && data.sites)
      ? data.sites.map((site: any) => ({
        value: site.address || getSiteCoordinateString(site.coordinates),
        label: site.name,
        type: 'site',
        id: site.id,
        siteType: site.type
      }))
      : []
  ), [loading, error, data, companyID]);
};

const ProjectSitesAddressInput = ({
  formik,
  name,
  openModal,
  closeModal,
  mobile,
  id,
  ...rest
}: any) => {
  const { selectedCompanyID: companyID } = useCompanyContext();
  const [selectedValue, setSelectedValue] = useState(null);

  // We need all suggestions in one single suggestions list
  // so we can index and scroll through them properly with the keyboard
  const suggestions = companyID ? useSiteSuggestions(`${companyID}`) : [];

  usePreselectValue(suggestions, formik.values[getSiteIDField(name)], selectedValue, setSelectedValue);
  const filteredOptions = useInputFilteredOptions(formik.values[name], selectedValue, suggestions);

  const onCreateSiteOption = (createdSite: any) => {
    formik.setFieldValue(getSiteIDField(name), createdSite.id);
    formik.setFieldValue(getSiteNameField(name), createdSite.name);
  };

  const onSelectOption = (o: any) => {
    let siteID = '';
    let siteName = '';
    if (o.type === 'site_create') {
      // Special site creation option, open site creation modal
      openModal(

        <MapEditContextProvider>
          <CreateSiteModal
            onClose={closeModal}
            onCreateSiteOption={onCreateSiteOption}
            siteName={o.name}
            mobile={mobile}
          />
        </MapEditContextProvider>,
      );
      return;
    }
    if (o.type === 'site') {
      siteID = o.id;
      // This check prevents `{dropOff|pickUp}SiteName`
      // from being wrongfully set to the address.
      siteName = o.label;
    }
    formik.setFieldValue(getSiteIDField(name), siteID);
    formik.setFieldValue(getSiteNameField(name), siteName);
    setSelectedValue(o);
  };

  const onKeyDown = (e: any) => {
    switch (e.key) {
      case 'Home':
        e.preventDefault();
        e.currentTarget.scrollLeft = 0;
        e.currentTarget.setSelectionRange(
          0,
          0,
        );
        break;
      case 'End':
        e.preventDefault();
        e.currentTarget.scrollLeft = e.currentTarget.scrollWidth;
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        );
        break;
      case 'Backspace':
        // If a site is selected, just clean out the input

        if (selectedValue && selectedValue.type === 'site') {
          e.preventDefault();
          setSelectedValue(null);
          formik.setFieldValue(getSiteIDField(name), '');
          formik.setFieldValue(getSiteNameField(name), '');
          formik.setFieldValue(name, '');
        }
        break;
      default:
        break;
    }
  };

  const onChange = (e: any) => {
    setSelectedValue(null);
    formik.setFieldValue(getSiteIDField(name), '');
    formik.setFieldValue(getSiteNameField(name), '');
    formik.setFieldValue(name, e.target.value);
  };


  const displayValue = selectedValue && selectedValue.type === 'site' ? selectedValue.label : formik.values[name];

  return (

    <FormikSitesAddressInput
      id={id}
      {...{
        formik,
        displayValue,
        name,
        selectedValue,
        setSelectedValue,
        suggestions: filteredOptions,
        onSelectOption,
        onKeyDown,
        onChange,
        ...rest,
      }}
    />
  );
};

export default connect(ProjectSitesAddressInput);
