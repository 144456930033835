// @ts-nocheck

import React from 'react';

import { FormField } from 'components/Common/Form';

import SitesAddressInput from 'components/Common/Form/AddressInput/SitesAddressInput';

import { SiteDetail } from 'components/Common/ProjectCard/components';

import { getSiteAddress } from 'pages/Projects/utils/projects.utils';

const FormikSitesAddressInput = ({
  formik,
  displayValue,
  label,
  required,
  name,
  embedded,
  readOnly,
  readOnlyProp,
  enableFreeformText,
  hint,
  tooltip,
  id,
  ...rest
}: any) => (

  <FormField
    label={label}
    required={required}
    error={formik.touched[name] && formik.errors[name]}
    embedded={embedded}
    hint={hint}
    tooltip={tooltip}
    id={id}
  >
    {!readOnly ? (

      <SitesAddressInput
        {...{
          formik,
          displayValue,
          label,
          required,
          name,
          embedded,
          enableFreeformText,
          ...rest,
        }}
      />
    ) : (

      <SiteDetail
        siteName={readOnlyProp && readOnlyProp.name}
        siteType={readOnlyProp && readOnlyProp.type}
        siteAddress={getSiteAddress(readOnlyProp) || formik.values[name]}
      />
    )
    }
  </FormField>
);
export default FormikSitesAddressInput;
