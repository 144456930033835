// @ts-nocheck
import { Formik, Form } from 'formik';
import React, { useEffect } from 'react';

import {
  mapFormToSiteInputValues,
  getMeasurementSuffix,
  siteTypeOptions,
  geofenceTypes,
  siteStatuses,

} from '../../utils/site.utils';
import { SiteActivity } from '../SiteActivity';
import { Button, CloseButton } from 'common-ui/Button';
import ID from 'common-ui/ConsistentIDs';



import {
  AddressAutocompleteInput,
  FormikTextInput,
  FormikDropdown,
  FormikTextArea,
  FormikSitesProductInput,
  FormikRadio,

} from 'components/Common/Form';

import { useGeocoderService } from 'components/Common/Form/AddressInput/GoogleAutocomplete';

import {
  useSitesMapSelect,
  useSitesGeoPointsSelect,
  useEditableMap,
  selectedLocationTypes,

} from 'components/Common/Map/utils/useEditableMap';
import QuestionMarkHoverIcon from 'components/Common/QuestionMarkHoverIcon';

import { useGetSiteIDSet } from 'pages/Sites/graphql';
import { injectIntlHOC } from 'utils/HOC';
import { useCompanyContext } from 'utils/Hooks';
import styles from './styles.module.scss';
import type { baseFormPropTypes } from '../../types';


export const validateLatLon = (locationString: any, intl: any) => {
  let vals = locationString.split('/');
  if (vals.length === 2) {
    const lat = parseFloat(vals[0]);
    const lon = parseFloat(vals[1]);
    if (Number.isFinite(lat) && Number.isFinite(lon)) {
      return intl.formatMessage({
        id: 'sites.errors.location_invalid_separator',
        message: 'GPS coordinates must be separated with a comma, i.e. Lat,Long',
      });
    }
  } else {
    vals = locationString.split(',');
    if (vals.length === 2) {
      const lat = parseFloat(vals[0]);
      const lon = parseFloat(vals[1]);
      // valid numbers, otherwise might be an address
      if (Number.isFinite(lat) && Number.isFinite(lon)) {
        if (lat < -90 || lat > 90) {
          return intl.formatMessage({
            id: 'sites.errors.location_lat',
            message: 'Latitude must be between -90 and 90 degrees',
          });
        }
        if (lon < -180 || lon > 180) {
          return intl.formatMessage({
            id: 'sites.errors.location_lon',
            message: 'Longitude must be between -180 and 180 degrees',
          });
        }
      }
    }
  }
  return null;
};

export const validateSitesForm = (intl: any, values: any, siteIdSet: any, site: any) => {
  const errors = {};

  if (!values.name.trim()) {

    errors.name = intl.formatMessage({
      id: 'sites.errors.name',
      defaultMessage: 'Please enter site name',
    });
  }
  if (!values.location.trim()) {

    errors.location = intl.formatMessage({
      id: 'sites.errors.location',
      defaultMessage: 'Please enter a location',
    });
  } else {
    const locationErrors = validateLatLon(values.location, intl);
    if (locationErrors) {

      errors.location = locationErrors;
    }
  }

  if (values.geofenceType === geofenceTypes.CIRCLE) {
    const radius = parseFloat(values.radius);
    if (!values.radius || Number.isNaN(radius) || radius <= 0) {

      errors.radius = intl.formatMessage({
        id: 'sites.errors.radius',
        defaultMessage: 'Please enter a valid geofence radius',
      });
    }
  }
  if (values.geofenceType === geofenceTypes.POLYGON) {
    if (!values.geoPoints || values.geoPoints.length < 3) {
      errors.geofenceType = 'Geofence must contain at least 3 points';
    }
  }
  if (values.externalID && values.externalID.trim()) {
    // If no site then the form is the new site form, so we only care about the uniqueness of the external ID
    // If site, then check the form is the edit form, and we want to make sure we aren't raising an error for having
    // it's original external ID
    if ((siteIdSet && siteIdSet.has(values.externalID))
    && ((!site) || (site && site.externalID !== values.externalID))) {

      errors.externalID = intl.formatMessage({
        id: 'sites.errors.external_id_exists',
        defaultMessage: 'A site with this site ID already exists',
      });
    }
  }

  return errors;
};

export const GeofenceRadiusTextInput = ({
  intl
}: any) => {
  const { selectedCompany } = useCompanyContext();
  return (

    <FormikTextInput
      name="radius"
      placeholder={intl.formatMessage({
        id: 'sites.add.radius_placeholder',
        defaultMessage: 'ex. 150',
      })}
      styleName={FormikTextInput.styleNames.measurement}
      decoratorContent={
        selectedCompany
          && getMeasurementSuffix(selectedCompany.measurementType)
      }
      min={0}
    />
  );
};

const SiteFormBaseUI = ({
  onClose,
  title,
  onSubmit,
  initialValues,
  submitText,
  intl,
  siteID,
  disableMapEdit,
  site,
}: baseFormPropTypes) => {
  const { selectedCompanyID } = useCompanyContext();
  const { stopEditingMap } = useEditableMap();
  const [siteIdSet] = useGetSiteIDSet();

  return (

    <div>

      <div className={styles.formHeader}>

        <span className={styles.textHeader}>
          {title}
        </span>

        <CloseButton
          onClick={() => {
            stopEditingMap();
            onClose();
          }}
        />
      </div>

      <Formik
        initialValues={{ companyId: selectedCompanyID, ...initialValues }}
        initialTouched={{ geofenceType: true }}
        enableReinitialize
        validate={(values: any) => validateSitesForm(intl, values, siteIdSet, site)}
        onSubmit={(values: any, formik: any) => onSubmit(mapFormToSiteInputValues(values))

          .then(() => {
            stopEditingMap();
            onClose();
            formik.resetForm();
          })
        }
      >

        {(formik: any) => <BaseFormRenderer
          {...formik}
          submitText={submitText}
          siteID={siteID}
          intl={intl}
          disableMapEdit={disableMapEdit}
          site={site}
        />}
      </Formik>
    </div>
  );
};

const BaseFormRenderer = ({
  isValid,
  values,
  dirty,
  setValues,
  submitText,
  siteID,
  intl,
  disableMapEdit,
  site
}: any) => {
  useSitesMapSelect(setValues, values);
  useSitesGeoPointsSelect(setValues, values);
  const getGeocode = useGeocoderService();
  const {
    setSelectedLocation,
    setCircleEditMode,
    setPolygonEditMode,
  } = useEditableMap();
  const { selectedCompany } = useCompanyContext();

  const [siteSiteIDSet] = useGetSiteIDSet();

  const { geofenceType, location } = values;
  // Change editing mode on map based on geofenceType value
  // But only if the focused site changes
  useEffect(() => {
    if (disableMapEdit) return;
    if (geofenceType === geofenceTypes.CIRCLE) {
      setCircleEditMode();
    } else if (geofenceType === geofenceTypes.POLYGON) {
      setPolygonEditMode();
    }
  }, [siteID]);

  return (

    <Form>

      <FormikTextInput
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Input,
          ID.Labels.SiteName,
        )}
        name="name"
        label={intl.formatMessage({
          id: 'sites.add.name_label',
          defaultMessage: 'Name',
        })}
        required
        placeholder={intl.formatMessage({
          id: 'sites.add.name_placeholder',
          defaultMessage: 'ex. Saskatoon Asphalt',
        })}
      />


      <AddressAutocompleteInput
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Input,
          ID.Labels.SiteLocation,
        )}
        name="location"
        label={intl.formatMessage({
          id: 'sites.add.location_label',
          defaultMessage: 'Location (Address or Lat,Long)',
        })}
        required
        placeholder={
          intl.formatMessage({
            id: 'sites.add.location_placeholder',
            defaultMessage: 'ex. 52.1332, 106.6700',
          })
        }
        onAddressSelect={(o: any) => {
          getGeocode(o.value, (details: any) => {
            const resolvedDetails = details[0]; // First result is probably good enough
            if (resolvedDetails) {
              setSelectedLocation((prev: any) => ({
                ...prev,
                lng: resolvedDetails.geometry.location.lng(),
                lat: resolvedDetails.geometry.location.lat(),
                type: selectedLocationTypes.ADDRESS
              }));
            }
          });
        }}
      />

      <FormikDropdown
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Dropdown,
          ID.Labels.SiteType,
        )}
        placeholder="Select site type"
        options={siteTypeOptions}
        name="siteType"
        label="Type"
      />


      <FormikSitesProductInput
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Dropdown,
          ID.Labels.SiteProduct,
        )}
        name="products"
        label="Products"
        selectedCompany={selectedCompany}
        siteID={siteID}
        initialValues={values}
      />


      <FormikTextInput
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Input,
          ID.Labels.SiteID,
        )}
        name="externalID"
        label="Site ID"
        placeholder={intl.formatMessage({
          id: 'sites.add_external_id_placeholder',
          defaultMessage: 'ex. 01234',
        })}
      />


      <FormikRadio
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Input,
          ID.Labels.SiteGeofence,
        )}
        required
        disabled={!location || disableMapEdit}
        label="Geofence"
        name="geofenceType"
        onChange={(value: any) => {
          if (value && value === geofenceTypes.CIRCLE) {
            setCircleEditMode();
          } else if (value && value === geofenceTypes.POLYGON) {
            setPolygonEditMode();
          }
        }}
        options={[
          {
            label: 'Circle',
            value: geofenceTypes.CIRCLE,
            children: <GeofenceRadiusTextInput {...{ intl, selectedCompany }} />,
          },
          {
            label: 'Draw Geofence',
            value: geofenceTypes.POLYGON,
          },
        ]}
      />


      <FormikRadio
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Input,
          ID.Labels.SiteStatus,
        )}
        label="Status"
        name="status"
        options={[{
          label: 'Active',
          value: siteStatuses.ACTIVE,
        }, {
          label: (
            <div className={styles.statusTemporaryLabel}>
              Temporary
              <QuestionMarkHoverIcon
                message="Sets site to inactive if not used for 30 days"
              />
            </div>
          ),
          value: siteStatuses.TEMPORARY,
        }, {
          label: 'Inactive',
          value: siteStatuses.INACTIVE,
        }]}
      />


      <FormikTextArea
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Input,
          ID.Labels.SiteNotes,
        )}
        label={intl.formatMessage({
          id: 'sites.add.notes_label',
          defaultMessage: 'Site Notes',
        })}
        placeholder={intl.formatMessage({
          id: 'sites.add.notes_placeholder',
          defaultMessage: 'Notes about the site...',
        })}
        name="notes"
      />


      <SiteActivity site={site} />


      <Button
        id={ID.create(
          ID.Areas.Sites,
          ID.Types.Button,
          ID.Labels.AddSite,
        )}
        type="submit"
        styleName="primary"
        fullWidth
        text={submitText}
        disabled={!isValid || !dirty}
      />
    </Form>
  );
};

export default injectIntlHOC(SiteFormBaseUI);
