// @ts-nocheck
import React from 'react';



import IdleIcon from '../svgs/idleIcon';

import LostConnectionIcon from '../svgs/lostConnectionIcon';
import OnlineDirectionIcon from '../svgs/onlineDirectionIcon';

import SiteInactiveIcon from '../svgs/siteInactiveIcon';

import {  MarkerType,  IconMarkerType } from '../types';

const rotate = (rotation: any) => ({
  transform: `rotate(${rotation}deg)`
});

const getSvgFromType = (type: string, rotation: number) => {
  switch (type) {
    case 'online':
    case 'onlineDirection':

      return <OnlineDirectionIcon style={rotate(rotation)} />;
    case 'idleDirection':
    case 'idle':

      return <IdleIcon />;
    case 'lostConnection':
    case 'badData':

      return <LostConnectionIcon />;
    case 'siteInactive':

      return <SiteInactiveIcon />;
    default:

      return <IdleIcon />;
  }
};

const getIconKey = ({
  id,
  type,
  rotation,
  text,
  transparent
}: any) =>
  `${id}-${type}-${rotation}-${transparent ? 'true' : 'false'}-${text}`;

const createMarker = (v: MarkerType) => {
  const { type, rotation, text, color, animate, transparent, lat, lng, id } = v;

  const svg = getSvgFromType(type, rotation);
  const iconWidth = 48;
  const iconHeight = 48;
  const iconKey = getIconKey(v);

  return {
    data: v,
    iconKey,
    lat,
    lng,
    id,
    Svg: svg,
    icon: {
      iconUrl: undefined,
      iconSize: [iconWidth, iconHeight],
      iconAnchor: [iconWidth / 2, iconHeight / 2],
      fillColor: color,
      color,
      animate,
      iconText: text,
      rotation,
      transparent,
    },
  };
};

const makeMarkers = (markerData: Array<MarkerType>): Array<IconMarkerType> => {
  const markers = markerData
    .filter((m) => !m.badData)
    .map((v) => createMarker(v));

  return markers;
};

export { createMarker, makeMarkers };
