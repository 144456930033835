// @ts-nocheck
import PhoneNumber from 'awesome-phonenumber';
import classNames from 'classnames';
import React from 'react';


import { Phone } from 'common-ui/Icon';
import { InfoCard } from './InfoCard';
import styles from './styles.module.scss';

type InfoCardHoverProps = {
  company: string,
  phoneNumber: string,
  isHovered: boolean,
};

const InfoCardHover = ({
  company,
  phoneNumber,
  isHovered,
  ...hireProps
}: InfoCardHoverProps) => {
  return (

    <InfoCard
      phoneNumber={phoneNumber}
      {...hireProps}

      company={(

        <div className={styles.hoverInfo}>
          <div className={classNames(styles.company, { [styles.hidden]: isHovered })}>
            {company}
          </div>
          <div className={classNames(styles.phoneNumber, { [styles.hidden]: !isHovered })}>
            <Phone />
            {PhoneNumber(phoneNumber).getNumber('national')}
          </div>
        </div>
      )}
    />
  );
};

export default InfoCardHover;
