// @ts-nocheck
import classNames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

type ToggleProps = {
  checked: boolean,
  onChange: (newValue: boolean) => void,
  label?: string,
  slim?: boolean,
};

const Toggle = ({
  checked,
  onChange,
  label,
  slim,
  ...inputProps
}: ToggleProps) => (

  <div className={styles.toggleContainer}>

    <input
      type="checkbox"
      hidden
      readOnly
      checked={checked}
      {...inputProps}
    />

    <div
      onClick={() => onChange(!checked)}
      onKeyPress={() => onChange(!checked)}
      role="checkbox"
      tabIndex={0}
      aria-checked={checked}

      className={classNames(styles.toggleControl, {
        [styles.selected]: checked,
        [styles.slim]: slim,
      })}
    >

      <div

        className={classNames(styles.toggleIndicator, {
          [styles.selected]: checked && !slim,
          [styles.slimSelected]: checked && slim,
          [styles.slim]: slim,
        })}
      />
    </div>

    {label && <div className={styles.toggleLabel}>{label}</div>}
  </div>
);

Toggle.defaultProps = {
  label: '',
  slim: false,
};

export default Toggle;
