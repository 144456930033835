// @ts-nocheck
import { filter, reduce } from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { FormattedMessage } from 'react-intl';


import { measurementTypes, metresToFeet, stringContains } from 'utils/format';
import type { SiteType } from '../types';


// calculate and return the job metadata

export const coordinatesToText = (coordinates: any) => coordinates.map((c: any) => `${c.latitude.toFixed(4)}, ${c.longitude.toFixed(4)}`).join('; ');

export const siteStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  TEMPORARY: 'temporary',
};

export const siteTypes = {
  RECEIVING: 'receiving',
  SOURCE: 'source',
  SOURCE_RECEIVING: 'source_receiving',
};

export const siteTypeOptions = [
  { label: 'Pick-Up', value: siteTypes.SOURCE },
  { label: 'Drop-Off', value: siteTypes.RECEIVING },
  { label: 'Both', value: siteTypes.SOURCE_RECEIVING },
];

export const geofenceTypes = {
  CIRCLE: 'circle',
  POLYGON: 'polygon',
};

export const getSiteTypeLabel = (siteType: any) => {
  switch (siteType) {
    case siteTypes.SOURCE:
      return 'Pick-up';
    case siteTypes.RECEIVING:
      return 'Drop-off';
    case siteTypes.SOURCE_RECEIVING:
      return 'Both';
    default:
      return '';
  }
};

// Create a marker from the job, which is the transformed job to be useable as a marker.
export const addSiteMetadata = (site: SiteType) => {
  const pointLocationLat = site.geofenceType === geofenceTypes.POLYGON && site.pointLocation && site.pointLocation.latitude
    ? site.pointLocation.latitude
    : null;
  const pointLocationLng = site.geofenceType === geofenceTypes.POLYGON && site.pointLocation && site.pointLocation.longitude
    ? site.pointLocation.longitude
    : null;
  return {
    ...site,
    type: site.status === siteStatuses.ACTIVE || site.status === siteStatuses.TEMPORARY ? 'site' : 'siteInactive',
    location: site.address || (site.coordinates && coordinatesToText(site.coordinates)),
    // site radius always starts in metres, convert to feet if needed
    radius: (site.company && site.company.measurementType === measurementTypes.IMPERIAL)
      ? metresToFeet(site.radius)
      : site.radius,
    measurementType: (site.company && site.company.measurementType) || measurementTypes.METRIC,
    radiusInMetres: site.radius,
    lat: pointLocationLat || (site.coordinates && site.coordinates.length > 0 && site.coordinates[0].latitude) || null,
    lng: pointLocationLng || (site.coordinates && site.coordinates.length > 0 && site.coordinates[0].longitude) || null,
    siteType: site.type,
  };
};

export const getMeasurementSuffix = (measurementType: any) => {
  switch (measurementType) {
    case measurementTypes.IMPERIAL:
      return (

        <FormattedMessage
          id="sites.measurement.feet"
          defaultMessage="feet"
        />
      );
    case measurementTypes.METRIC:
    default:
      return (

        <FormattedMessage
          id="sites.measurement.metres"
          defaultMessage="metres"
        />
      );
  }
};

export const parseLocationText = (location: any, geofenceType: any, geoPoints: any) => {
  if (geofenceType === geofenceTypes.POLYGON) {
    // convert geoPoints to a list of { latitude, longitude } objects
    const coordinates = geoPoints.map((p: any) => ({
      longitude: p[0],
      latitude: p[1]
    }));
    return { coordinates, address: location };
  }
  const loc = location.split(',').map((l: any) => l.trim());

  if (loc && loc.length === 2) {
    const latitude = parseFloat(loc[0]);
    const longitude = parseFloat(loc[1]);
    if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
      return {
        coordinates: [{
          latitude,
          longitude,
        }],
      };
    }
  }

  return {
    address: location,
  };
};

export const filterSites = ({
  sites,
  siteFilter,
  selectedFilterTab
}: any) => (
  sites.filter((s: any) => (s.status === selectedFilterTab
    || (s.status === siteStatuses.TEMPORARY && selectedFilterTab === siteStatuses.ACTIVE)
  ) && (
    !siteFilter
  || stringContains(s.name, siteFilter)
  || stringContains(s.location, siteFilter)
  ))
);

export const getSiteBySiteID = ({
  sites,
  siteID
}: any) => sites.find((s: any) => s && s.id === siteID);

export const getSiteNameByID = ({
  sites,
  siteID
}: any) => {
  const site = getSiteBySiteID({ sites, siteID });
  if (site && site.name) {
    return site.name;
  }
  return '';
};

export const getSiteProductsBySiteID = ({
  sites,
  siteID
}: any) => {
  const site = getSiteBySiteID({ sites, siteID });
  if (site && site.products) {
    return site.products;
  }
  return [];
};

export const getCreatedBy = (parent: any, createdBy: any) => {
  const account = createdBy || parent.createdBy;
  if (account && account.firstName) {
    return `${account.firstName} ${account.lastName}`;
  }
  return '';
};

export const getActiveSite = (site: SiteType) => site.status === siteStatuses.ACTIVE || site.status === siteStatuses.TEMPORARY;
export const isSiteTypeReceivingOrBoth = (site: SiteType) => {
  if (site.type) {
    return site.type === siteTypes.RECEIVING || site.type === siteTypes.SOURCE_RECEIVING;
  }
  return false;
};

export const isSiteTypeSourceOrBoth = (site: SiteType) => {
  if (site.type) {
    return site.type === siteTypes.SOURCE || site.type === siteTypes.SOURCE_RECEIVING;
  }
  return false;
};

export const generateSiteOptionsForSite = (site: SiteType) => (
  {
    label: site.name,
    value: site.id,
    id: site.id,
    address: site.address,
    name: site.name,
  }
);

export const mapFormToSiteInputValues = ({
  location,
  radius,
  companyId,
  siteType,
  products,
  geofenceType,
  geoPoints,
  externalID,
  ...restValues
}: any) => ({
  ...restValues,
  ...(parseLocationText(location, geofenceType, geoPoints)),
  companyId: parseInt(companyId, 10),
  radius: parseFloat(radius),
  type: siteType,
  products: products.length > 0
    ? products.map(({
      name,
      id
    }: any) => ({ name, productId: parseInt(id, 10) }))
    : [],
  geofenceType,
  externalID,
});

export const getAverageTimeOnSiteMinutes = (siteId: any, siteCycles: any) => {
  const timesOnSite = siteCycles.map(({
    pickupSiteID,
    dropoffSiteID,
    pickupDuration,
    dropoffDuration
  }: any) => {
    if (pickupSiteID === siteId && pickupDuration) {
      return moment.duration(pickupDuration).asMinutes();
    }
    if (dropoffSiteID === siteId && dropoffDuration) {
      return moment.duration(dropoffDuration).asMinutes();
    }
    return null;
  });

  const filteredTimesOnSite = filter(timesOnSite, (t: any) => !!t);
  return Math.round(
    reduce(filteredTimesOnSite, (sum: any, n: any) => {
      return sum + n;
    }, 0) / filteredTimesOnSite.length,
  );
};

const median = (values: any) => {
  values.sort((a: any, b: any) => a - b);
  const half = Math.floor(values.length / 2);
  if (values.length % 2) {
    return values[half];
  }
  return (values[half - 1] + values[half]) / 2.0;
};

export const getMedianTimeOnSiteMinutes = (siteId: any, siteCycles: any) => {
  const timesOnSite = siteCycles.map(({
    pickupSiteID,
    dropoffSiteID,
    pickupDuration,
    dropoffDuration
  }: any) => {
    if (pickupSiteID === siteId && pickupDuration) {
      return moment.duration(pickupDuration).asMinutes();
    }
    if (dropoffSiteID === siteId && dropoffDuration) {
      return moment.duration(dropoffDuration).asMinutes();
    }
    return null;
  });

  const filteredTimesOnSite = filter(timesOnSite, (t: any) => !!t);
  return Math.round(
    median(filteredTimesOnSite),
  );
};
