// @ts-nocheck
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import type { Assignment, Ticket } from 'common-ui/types';
import { travelTimeValueType } from 'pages/Projects/components/TravelTimeInput/Constants';
import { undefinedOrNull, assignedStatuses, sendStatuses } from 'pages/Projects/utils/projects.utils';
import { rateUnits } from 'utils/format/rateUnits';
import type { ModifiedProjectType, ModifiedAssignmentType } from '../types';

export const getMissingAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        a.assignedStatus === assignedStatuses.MISSING
        || a.assignedStatus === assignedStatuses.DECLINED
          ? atotal + 1 : atotal
      ), 0)
    ), 0)
    : 0
);

function getDropoffDeparture(inputAssignment, company) {
  if (!inputAssignment) {
    return null;
  } if (inputAssignment.dropoffDeparture) {
    return inputAssignment.dropoffDeparture;
  } if (inputAssignment.startTime && company) {
    return moment(inputAssignment.startTime).add(company.scheduleViewDefaultDuration, 'minutes').toISOString();
  }

  return null;
}

export const getDraftAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        a.isDraft && a.assignedStatus !== assignedStatuses.MISSING
          ? atotal + 1
          : atotal
      ), 0)
    ), 0)
    : 0
);

export const getAcceptedAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        a.sendStatus === sendStatuses.ACCEPTED ? atotal + 1 : atotal
      ), 0)
    ), 0)
    : 0
);

export const getLiveAssignmentsCount = (projects:any) => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        a.sendStatus === sendStatuses.LOGGING ? atotal + 1 : atotal
      ), 0)
    ), 0)
    : 0
);

export const getPendingAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        a.sendStatus === sendStatuses.PENDING ? atotal + 1 : atotal
      ), 0)
    ), 0)
    : 0
);

export const getAutoAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        a.assignedStatus === assignedStatuses.AUTO ? atotal + 1 : atotal
      ), 0)
    ), 0)
    : 0
);

export const getDoneAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.assignments.reduce((atotal, a) => (
        (a.sendStatus === sendStatuses.DONE && a.assignedStatus !== assignedStatuses.AUTO) ? atotal + 1 : atotal
      ), 0)
    ), 0)
    : 0
);

export const getCancelledAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.cancelledAssignments.length
    ), 0)
    : 0
);

export const getUnassignedAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.unassignedAssignments.length
    ), 0)
    : 0
);

export const getReassignedAssignmentsCount = projects => (
  projects.length > 0
    ? projects.reduce((total, p) => (
      total + p.reassignedAssignments.length
    ), 0)
    : 0
);

export const getSelectedAssignments = (
  projects: ModifiedProjectType[],
  selectedAssignmentIds: Set<string>,
  selectedDraftIds: Set<string>,
) => {
  const selectedAssignments: ModifiedAssignmentType[] = projects.reduce((values, p) => (
    values.concat(
      p.assignments.filter(a => (!a.isDraft && selectedAssignmentIds.has(a.id))),
      p.cancelledAssignments.filter(a => selectedAssignmentIds.has(a.id)),
    )
  ), []);

  const selectedDraftAssignments: ModifiedAssignmentType[] = projects.reduce((values, p) => (
    values.concat(
      p.assignments.filter(a => (a.isDraft && selectedDraftIds.has(a.id))),
    )
  ), []);

  return [...selectedAssignments, ...selectedDraftAssignments];
};

export const getIfSelectedAssignmentsHaveSubcontract = (
  projects,
  selectedIds,
  selectedDraftIds,
) => (
  projects.length > 0
    ? projects.find(
      p => (
        p.isSubcontract
        && (
          _.find(
            p.assignments,
            ad => (
              ad.isDraft
              && selectedDraftIds.has(ad.id)
            ),
          ) || _.find(
            p.assignments,
            a => (
              !a.isDraft
              && selectedIds.has(a.id)
            ),
          )
        )
      ),
    ) : false
);

// Get all assignment Ids that are selectable
// assignments cannot be draft, and must not have ended yet.
export const getAllAssignmentIds = ({ projects, includeUnselectableIds = false }) => (
  projects.length > 0
    ? projects.reduce((values, p) => (
      values.concat(
        p.assignments.filter(
          a => !a.isDraft
            && (includeUnselectableIds || (!p.hasEnded && a.sendStatus !== sendStatuses.DONE)),
        ).map(a => a.id),
        p.cancelledAssignments.filter(
          a => !a.isDraft
            && (includeUnselectableIds || (!p.hasEnded && !p.isSubcontract)),
        ).map(a => a.id),
      )
    ), [])
    : []
);


export const getAllScheduleAssignmentIds = ({ projects }) => (
  projects.length > 0
    ? projects.reduce((values, p) => (
      values.concat(
        p.assignments.filter(
          a => !a.isDraft
            && (!p.hasEnded && a.sendStatus !== sendStatuses.DONE && (!!a.scheduleId
              || !!a.assignedTo || !!a.assignee)),
        ).map(a => a.id),
        p.cancelledAssignments.filter(
          a => !a.isDraft
            && (!p.hasEnded && a.sendStatus !== sendStatuses.DONE && (!!a.scheduleId
              || !!a.assignedTo || !!a.assignee)),
        ).map(a => a.id),
      )
    ), [])
    : []
);

export const getAllScheduleDraftAssignmentIds = ({ projects }) => (
  projects.length > 0
    ? projects.reduce((values, p) => (
      values.concat(
        p.assignments.filter(
          a => !!a.isDraft
            && (!p.hasEnded && a.sendStatus !== sendStatuses.DONE && (!!a.scheduleId
              || !!a.assignedTo || !!a.assignee)),
        ).map(a => a.id),
        p.cancelledAssignments.filter(
          a => !!a.isDraft
            && (!p.hasEnded && a.sendStatus !== sendStatuses.DONE && (!!a.scheduleId
              || !!a.assignedTo || !!a.assignee)),
        ).map(a => a.id),
      )
    ), [])
    : []
);

// Get all assigment draft ids that are selectable
// drafts cannot be assignments, and must not have ended yet.
export const getAllAssignmentDraftIds = ({ projects, includeUnselectableIds = false }) => (
  projects.length > 0
    ? projects.reduce((values, p) => (
      values.concat(p.assignments.filter(
        a => a.isDraft
          && (includeUnselectableIds || !p.hasEnded),
      ).map(a => a.id))
    ), [])
    : []
);

export const getAllProjectIds = projects => (
  projects.length > 0
    ? projects.reduce((values, p) => values.concat(p.id), [])
    : []
);

export const isAssignmentTypeSmart = (
  assignment: ModifiedAssignmentType,
) => !!assignment.scheduleId;

export const getAssignmentPickUpAndPickUpSite = (assignment) => {
  if (!assignment) {
    return ['', null];
  }

  const { pickUpSite } = assignment;
  const defaultPickUp = assignment.pickUp || '';
  const pickUp = pickUpSite ? pickUpSite.name : defaultPickUp;

  return [pickUp, pickUpSite];
};

export const getSmartAndClassicAssignmentHireIds = projects => projects.reduce(
  (acc, project) => {
    const allAssignments = [...project.assignments, ...project.cancelledAssignments]
      .filter(a => a.sendStatus !== sendStatuses.DONE);
    allAssignments.forEach((a) => {
      if (a.assignee) {
        if (isAssignmentTypeSmart(a)) {
          acc.smart.push(a.assignee.id);
        } else {
          acc.classic.push(a.assignee.id);
        }
      }
    });
    return acc;
  }, { smart: [], classic: [] },
);

export const checkIfHireCanBeAssignedToAssignment = ({
                                                       smartAssignmentHireIds,
                                                       classicAssignmentHireIds,
                                                     }) => assignment => (hire) => {
  if (!assignment) {
    return true;
  }

  // if smart assignment
  if (isAssignmentTypeSmart(assignment)) {
    // hire cannot be assigned if already assigned to a classic assignment
    return !classicAssignmentHireIds.find(id => id === hire.id);
  }

  // if classic assignment
  // hire cannot be assigned if already assigned to a smart assignment
  return !smartAssignmentHireIds.find(id => id === hire.id);
};

export const assignmentsHaveSameCompanyIds = (assignments) => {
  const firstCompanyId = (
    assignments.length
    && assignments[0].project.companyId
  );
  const assignmentsHaveDifferentCompanyIds = assignments
    .map(a => a.project.companyId)
    .some(id => id !== firstCompanyId);

  return !assignmentsHaveDifferentCompanyIds;
};

export const initialValuesForAssignment = (company, inputAssignment?: ModifiedAssignmentType) => {
  const [pickUp, pickUpSite] = getAssignmentPickUpAndPickUpSite(inputAssignment);
  const dropoffDeparture = getDropoffDeparture(inputAssignment, company);

  const companyMeasurementType = (company && company.measurementType);
  const defaultMeasurementType = companyMeasurementType === 'metric' ? rateUnits.TONNE : rateUnits.TON;

  const assignmentConfigurableFieldsObj = (inputAssignment && inputAssignment.configurableFields
    && inputAssignment.configurableFields.reduce((acc, field) => {
      const { id, value } = field;
      return { ...acc, [`configurableFields-${id}`]: value };
    }, {})) || {};

  const vals = {
    startTime: (inputAssignment && inputAssignment.startTime) || '',
    equipment: (inputAssignment && inputAssignment.equipment) || '',
    equipmentInfo: (inputAssignment && inputAssignment.equipmentInfo) || null,
    invoiceRate: (inputAssignment && inputAssignment.invoiceRate && inputAssignment.invoiceRate.toFixed(2)) || '0.00',
    invoiceRateUnit: (inputAssignment && inputAssignment.invoiceRateUnit) || defaultMeasurementType,
    haulerRate: (inputAssignment && inputAssignment.haulerRate && inputAssignment.haulerRate.toFixed(2)) || '0.00',
    haulerRateUnit: (inputAssignment && inputAssignment.haulerRateUnit) || defaultMeasurementType,
    hideRateFromAssignee: inputAssignment && _.isBoolean(inputAssignment.hideRateFromAssignee)
      ? inputAssignment.hideRateFromAssignee
      : null,
    phase: (inputAssignment && inputAssignment.phase) || '',
    product: (inputAssignment && inputAssignment.product) || '',
    productInfo: (inputAssignment && inputAssignment.productInfo) || null,
    notes: (inputAssignment && inputAssignment.notes) || '',
    companyId: inputAssignment && inputAssignment.project && inputAssignment.project.companyId,
    pickUp,
    pickUpSiteID: pickUpSite ? pickUpSite.id : '',
    dropOff: (inputAssignment && inputAssignment.dropOffSite && inputAssignment.dropOffSite.name) || (inputAssignment && inputAssignment.dropOff) || '',
    dropOffSiteID: (inputAssignment && inputAssignment.dropOffSite && inputAssignment.dropOffSite.id) || null,
    hotDispatchCompanyId: inputAssignment && inputAssignment.project && inputAssignment.hotDispatchCompanyId
    && inputAssignment.hotDispatchCompanyId !== inputAssignment.project.companyId ? inputAssignment.hotDispatchCompanyId : null,
    quantity: inputAssignment && (Number.isFinite(inputAssignment.quantity) ? String(inputAssignment.quantity) : ''),
    quantityUnit: (inputAssignment && inputAssignment.quantityUnit) || null,
    dropoffDeparture,
    travelTimeUnitType:
      (inputAssignment && inputAssignment.travelTimeUnitType)
      || travelTimeValueType.minutes.value,
    travelTime: (inputAssignment && inputAssignment.travelTime) || 0,
    hasTimesheet: (inputAssignment && inputAssignment.hasTimesheet) || false,
    subcontractorIndex: (inputAssignment && inputAssignment.subcontractorIndex) || null,
    duration: (inputAssignment && moment(dropoffDeparture).diff(inputAssignment.startTime, 'minutes')),
    configurableFields: (inputAssignment && inputAssignment.configurableFields) || [],
    ...assignmentConfigurableFieldsObj,

    // Target read-only fields
    targetReadOnlyFields: inputAssignment && inputAssignment.project && inputAssignment.project.target && inputAssignment.project.target.readOnlyFields
      ? inputAssignment.project.target.readOnlyFields
      : [],
  };

  return vals;
};

export const useGetMostRecentAssignmentInstance = (assignment) => {
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    const { assignmentInstances } = assignment;
    if (assignmentInstances.length === 0) { return; }

    setInstance(
      assignmentInstances.reduce((newest, curr) => {
        const date1 = new Date(newest.createdAt);
        const date2 = new Date(curr.createdAt);
        return (date1.getTime() > date2.getTime() ? newest : curr);
      }),
    );
  }, [instance, setInstance, assignment]);
  return instance;
};

export const getAssignmentCount = (projects: ModifiedProjectType[]) => (
  projects.reduce((total, p) => (
    total + p.assignments.length + p.cancelledAssignments.length + p.unassignedAssignments.length + p.reassignedAssignments.length
  ), 0)
);

export const getProjectQuantities = (projects: ModifiedProjectType[], attribute: string, isTrackingCycles: boolean) => (
  // Subcontracted projects will not be included in the total project quantities
  projects
    .filter(p => !p.isSubcontract)
    .reduce((total, p) => {
      const qTotal = total[p.quantityUnit] || { total: 0, [attribute]: 0 };
      qTotal.total += p.quantity;

      const getProjectQuantitiesReducer = (atotal: number, a: ModifiedAssignmentType): number => {
        // Only count quantities for assignments that match their project and have a driver assigned
        if (a.quantityUnit === p.quantityUnit && a.assignedStatus !== assignedStatuses.MISSING) {
          if (attribute === 'quantityDelivered' && isTrackingCycles && !undefinedOrNull(a.quantityDeliveredOverride)) {
            return atotal + a.quantityDeliveredOverride;
          }
          return atotal + (a[attribute] || 0);
        }
        return atotal;
      };

      const sumedATotal = p.assignments.reduce(getProjectQuantitiesReducer, qTotal[attribute]);
      const sumedCancelledWithATotal = p.cancelledAssignments.reduce(getProjectQuantitiesReducer, sumedATotal);
      qTotal[attribute] = sumedCancelledWithATotal;

      return {
        ...total,
        [p.quantityUnit]: qTotal,
      };
    }, {})
);

export const getDropOffAndDropOffSite = (assignment: Assignment, ticket?: Ticket, ) : any => {
  if (!ticket && !assignment) {
    return ['', null];
  }

  return [(ticket && ticket.dropOff) ? ticket.dropOff : assignment.dropOff,
    (ticket && ticket.dropOffSite) ? ticket.dropOffSite : assignment.dropOffSite];
};

export const getAssignmentHireVehicleNumbers = (assignment: Assignment, assignmentInstance) => {
  let truckNumber = '';
  let licensePlate = '';
  let trailerNumber = '';


  if (!assignment || !assignment.assignee) {
    return [truckNumber, licensePlate, trailerNumber];
  }
  if (!assignmentInstance) {
    ({ truckNumber, licensePlate, trailerNumber } = assignment.assignee);
    return [truckNumber, licensePlate, trailerNumber];
  }

  const assignmentHire = assignment.assignee;
  const instanceHire = assignmentInstance.assignee;

  if (assignmentHire.id === instanceHire.id) {
    ({ truckNumber, licensePlate, trailerNumber } = assignmentInstance);
  } else {
    ({ truckNumber, licensePlate, trailerNumber } = assignmentHire);
  }

  return [truckNumber, licensePlate, trailerNumber];
};
