// @ts-nocheck
import _ from 'lodash';
import moment from 'moment';


import type { InternalHire } from 'pages/Hires/types';
import type {
  ProjectType,
  Job,
  AssignmentJobCounts,
  JobMetaData,
} from '../types';

// gets the initials as text i.e 'John Smith' = 'JS'.
const getInitials = (assignee?: InternalHire) => `${
  !!assignee && !!assignee.firstName && assignee.firstName.length > 0
    ? assignee.firstName[0]
    : ''
}${
  !!assignee && !!assignee.lastName && assignee.lastName.length > 0
    ? assignee.lastName[0]
    : ''
}`;

// calculate and return the job metadata
const getJobMetaData = ({
  assignedTo: {
    gps: {
      id,
      latitude,
      longitude,
      bearing,
      idleSince,
      isIdle,
      isDisconnected,
      lastPing,
    },
  },
}: Job): JobMetaData => {
  const metadata = {
    type: 'onlineDirection',
    badData: false,
    idleSince: undefined,
    isIdle: false,
    bearing: 0,
    isDisconnected: false,
  };


  const metadataLastPing = lastPing ? moment(lastPing) : null;

  let lastPingDiff = 0;
  if (metadataLastPing) {

    lastPingDiff = moment().diff(metadataLastPing, 'minutes');
  }

  if (isDisconnected || lastPingDiff >= 10) {
    metadata.isDisconnected = true;
  }

  if (!id || parseInt(id, 10) <= 0 || (!latitude && !longitude)) {
    // This means there's no actual GPS data associated with this assignment
    // Meaning the user probably turned off GPS/did not grant GPS Permissions
    metadata.badData = true;

    metadata.idleSince = idleSince;
    metadata.isIdle = isIdle;
  }
  if (!metadata.badData && !metadata.isDisconnected && isIdle) {

    metadata.idleSince = idleSince;
    metadata.isIdle = isIdle;
  }

  if (bearing) {
    // TODO: Calculate bearing from direction into 360 direction
    metadata.bearing = bearing;
  }

  if (metadata.isDisconnected) {
    metadata.type = 'lostConnection';
    metadata.bearing = 0;
  } else if (metadata.badData) {
    metadata.type = 'badData';
    metadata.bearing = 0;
  } else if (metadata.isIdle) {
    metadata.type = 'idle';
    metadata.bearing = 0;
  }
  return _.merge({ lastPing: metadataLastPing }, metadata);
};

// / Updates the count based on the type of job
const updateAssignmentCounts = (
  counts: AssignmentJobCounts,
  liveAssignment: Job,
): AssignmentJobCounts => {
  const jobType = getJobMetaData(liveAssignment).type;
  const newCounts = _.merge({}, counts);

  if (jobType === 'idle') {
    newCounts.idle = counts.idle + 1;
  }
  if (jobType === 'onlineDirection') {
    newCounts.online = counts.online + 1;
  }
  if (jobType === 'lostConnection') {
    newCounts.disconnected = counts.disconnected + 1;
  }
  if (jobType === 'badData') {
    newCounts.badData = counts.badData + 1;
  }

  return newCounts;
};

export const undefinedOrNull = (obj: any) => obj === undefined || obj === null;

// Create a marker from the job, which is the transformed job to be useable as a marker.
const createMarkerFromAssignment = (
  assignment: Job,
  liveProject: ProjectType,
) => {
  const metadata = getJobMetaData(assignment);
  const {
    id,
    instance: {
      loggingStart,
      truckNumber,
      licensePlate,
    },
    assignedTo: {
      assignee,
      gps: {
        latitude,
        longitude,
      },
    },

    quantity,
    quantityUnit,
  } = assignment;

  const newMarker = {
    id: `${assignment.id}-${liveProject.id}`,
    _parentID: liveProject.id,
    _assignmentID: id,
    lat: latitude,
    lng: longitude,
    name: `${(assignee && assignee.firstName) || ''} ${(assignee && assignee.lastName) || ''}`,
    firstName: assignee && assignee.firstName,
    lastName: assignee && assignee.lastName,
    companyName: assignee && assignee.companyName,
    jobNumber: liveProject.jobNumber,
    idleSince: metadata.idleSince,
    animate: !!metadata.idleSince,
    badData: metadata.badData,
    transparent: !!metadata.badData,
    phoneNumber: assignee && assignee.phoneNumber,

    startTime: moment(loggingStart).format('h:mm a'),
    lastPingTime: metadata.lastPing,
    accountId: assignee && assignee.accountId,
    truckNumber,
    licensePlate,
    type: metadata.type,
    rotation: metadata.bearing,
    text: getInitials(assignee),
    initials: getInitials(assignee),
    client: liveProject.client,
    projectName: liveProject.name,
    activeCycle: assignee.activeCycle,
    quantity,
    quantityUnit,
    showName: liveProject.showName,
    showPhoneNumber: liveProject.showPhoneNumber,
    showStatus: liveProject.showStatus,
    showTimecard: liveProject.showTimecard,

    showLicensePlate: liveProject.showLicensePlate,
  };

  return newMarker;
};

export {
  getInitials, getJobMetaData, createMarkerFromAssignment, updateAssignmentCounts,
};
