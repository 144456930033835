// @ts-nocheck

import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { usePermissions } from 'common-ui/Hooks';

import { ALL_NOTIFICATIONS_AND_REQUESTS_QUERY } from 'components/NotificationsSidebar/gql';
import { useCompanyContext } from 'utils/Hooks';


// pollInterval of 0 implies NO polling
const useNotificationsAndRequests = (pollInterval = 0) => {
  const { selectedCompanyID } = useCompanyContext();
  const { ViewSubdispatchRequests, ViewAllInternalRequests, ViewOwnInternalRequests, ViewAllExternalRequests } = usePermissions(selectedCompanyID);
  const canQuery = ViewSubdispatchRequests || ViewAllInternalRequests || ViewOwnInternalRequests || ViewAllExternalRequests;

  const skip = !canQuery || !selectedCompanyID || selectedCompanyID === '0';

  const { loading, error, data } = useQuery(ALL_NOTIFICATIONS_AND_REQUESTS_QUERY, {

    name: 'notificationsAndRequests',
    skip,
    variables: {
      companyID: selectedCompanyID,
    },
    // Apparently if you have a non-zero poll interval on the follow-up
    // queries it will ignore if the query is supposed to be skipped.
    pollInterval: !skip ? pollInterval : 0,
  });

  return useMemo(() => (
    (!loading && !error && data)
      ? data
      : { notifications: [], requests: [] }
  ), [data, loading, error]);
};


export default useNotificationsAndRequests;
