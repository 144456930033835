// @ts-nocheck
import { connect } from 'formik';
import React, { useState } from 'react';

import FormikSitesAddressInput from '../Formik/FormikSitesAddressInput';
import {
  useAddressAutocomplete,

} from './GoogleAutocomplete';


const AddressAutocompleteInput = ({
  formik,
  name,
  onAddressSelect,
  ...rest
}: any) => {
  const [suggestions] = useAddressAutocomplete(formik.values[name]);
  const [selectedValue, setSelectedValue] = useState(null);

  const onSelectOption = (o: any) => {
    formik.setFieldValue(name, o.value);
    setSelectedValue(o);
    if (onAddressSelect) onAddressSelect(o);
  };

  const onKeyDown = (e: any) => {
    switch (e.key) {
      case 'Home':
        e.preventDefault();
        e.currentTarget.scrollLeft = 0;
        e.currentTarget.setSelectionRange(
          0,
          0,
        );
        break;
      case 'End':
        e.preventDefault();
        e.currentTarget.scrollLeft = e.currentTarget.scrollWidth;
        e.currentTarget.setSelectionRange(
          e.currentTarget.value.length,
          e.currentTarget.value.length,
        );
        break;
      default:
        break;
    }
  };

  const onChange = (e: any) => {
    setSelectedValue(null);
    formik.setFieldValue(name, e.target.value);
    formik.setFieldTouched(name);
  };

  return (

    <FormikSitesAddressInput
      addressesEnabled
      {...{
        formik,
        name,
        selectedValue,
        setSelectedValue,
        suggestions,
        onSelectOption,
        onKeyDown,
        onChange,
        ...rest,
      }}
    />
  );
};

export default connect(AddressAutocompleteInput);
