// @ts-nocheck
import React, { useEffect, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';

type TooltipWrapperProps = {
  message?: string,
  children?: ReactNode,
  rebuildOnMessageChange: boolean,
};

const TooltipWrapper = ({
  message,
  children,
  rebuildOnMessageChange,
  ...tooltipProps
}: TooltipWrapperProps) => {
  const dependencies = rebuildOnMessageChange ? [message] : [];

  useEffect(() => ReactTooltip.rebuild(), dependencies);

  return (

    <div
      data-tip={message}
      data-for="tooltip"
      {...tooltipProps}
    >
      {children}
    </div>
  );
};

TooltipWrapper.defaultProps = {
  message: '',
  children: null,
  rebuildOnMessageChange: false,
};

export default TooltipWrapper;
