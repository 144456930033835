import React from 'react';
import styles from './styles.module.scss';

const DotSeparator = () => (

  <div className={styles.dotSeparator}>

    <span>&#183;</span>
  </div>
);

export default DotSeparator;
