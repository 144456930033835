import React, {SVGProps} from 'react';

const Phone = (props: SVGProps<SVGSVGElement>) => {
  return (
     
    <svg
      width="14px"
      height="14px"
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#4D4D4D"
      {...props}
    >
       
      <title>Phone</title>
       
      <desc>Created with sketchtool.</desc>

       
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
         
        <path d="M6.15028142,2.82797653 C6.40577236,3.08346747 6.40634597,3.49712652 6.14964475,3.75382775 L5.45759861,4.4458739 C5.07522518,4.82824733 5.07592712,5.45012356 5.45992166,5.83411811 L7.76585572,8.1400522 C8.15233569,8.52653217 8.77138836,8.52508681 9.15409991,8.14237525 L9.84614605,7.4503291 C10.1019886,7.19448653 10.5157848,7.19347998 10.7719973,7.44969242 L13.3121932,9.98988836 C13.6953121,10.3730073 13.6970876,10.9923902 13.3106077,11.3788701 L11.9253518,12.764126 C11.0328807,13.6565971 9.41271944,13.8913118 8.32926587,13.24296 C8.32926587,13.24296 6.59382409,12.5127726 3.85686991,9.77581836 C1.11991573,7.03886415 0.362850577,5.27114971 0.362850577,5.27114971 C-0.268391284,4.17731336 -0.0542147179,2.56468459 0.835847896,1.67462196 L2.22110376,0.289366078 C2.6050983,-0.0946284678 3.22455836,-0.0977465682 3.61008552,0.287780593 L6.15028142,2.82797653 L6.15028142,2.82797653 Z M2.91638737,1.44233305 L1.75997323,2.59874721 C1.28721474,3.0715057 1.20721524,4.01759067 1.49479595,4.61791537 C1.49479595,4.61791537 1.60572651,4.8442187 1.67119405,4.96422251 C1.79330583,5.18805678 1.95641091,5.45561446 2.16535384,5.76390523 C2.78291841,6.67510838 3.64176503,7.71246263 4.7809952,8.85169282 C5.92004556,9.99074318 6.95098302,10.8442492 7.85144972,11.4530852 C8.15545118,11.6586309 8.41837561,11.8181706 8.6374851,11.9367779 C8.75400486,11.9998518 9.00035591,12.1215083 9.00035591,12.1215083 C9.54739782,12.4078038 10.5200056,12.3212212 11.0012264,11.8400004 L12.1576405,10.6835863 L10.3093899,8.83533562 L10.0782251,9.06650037 C9.18409463,9.96063089 7.7375514,9.95999839 6.84173034,9.06417732 L4.5357963,6.75824327 C3.64216018,5.86460714 3.63997906,4.41524265 4.53347325,3.52174845 L4.764638,3.29058369 L2.91638737,1.44233305 L2.91638737,1.44233305 Z" />
      </g>
    </svg>
  );
};

export default Phone;
