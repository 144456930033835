import React, {SVGProps} from 'react';

const SixDots = (props: SVGProps<SVGSVGElement>) => (

  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >

    <circle cx="8" cy="4" r="2" fill="#D8D8D8" />

    <circle cx="8" cy="12" r="2" fill="#D8D8D8" />

    <circle cx="8" cy="20" r="2" fill="#D8D8D8" />

    <circle cx="16" cy="4" r="2" fill="#D8D8D8" />

    <circle cx="16" cy="12" r="2" fill="#D8D8D8" />

    <circle cx="16" cy="20" r="2" fill="#D8D8D8" />
  </svg>
);

export default SixDots;
