// @ts-nocheck
import {
  useQuery, useLazyQuery, useMutation, gql,
} from '@apollo/client';
import moment from 'moment-timezone';
import queryString from 'query-string';
import { useMemo, useCallback } from 'react';


import { useLocation } from 'react-router-dom';
import { useTimezone, usePermissions } from 'common-ui/Hooks';
import { useCompanyContext } from 'utils/Hooks';
import { HireTypes } from './utils';
import type { InternalHire, Subcontractor } from './types';

export const COMPANIES_QUERY = gql`
  query CompaniesQuery($isAdmin: Boolean, $isExternalGuest: Boolean) {
    companies(isAdmin: $isAdmin, isExternalGuest: $isExternalGuest) {
      id
      name
      address1
      address2
      city
      description
      provinceState
      postalCodeZip
      country
      timezone
      isAdmin
      measurementType
      ticketDefaults
      requestTimesheets
      tierName
      timesheetDefaultAdjustments {
        id
        type
        minutes
        timing
        deleted
      }
      logo {
        getURL
        filename
      }
      coordinates {
        latitude
        longitude
      }
      isExternalGuest
      isExternalDOT
    }
  }
`;

export const useHiresCompanies = (isAdmin = true, isExternalGuest = false) => {
  const { loading, error, data } = useQuery(COMPANIES_QUERY, {
    variables: {
      isAdmin,
      isExternalGuest,
    },
  });
  return useMemo(() => (!loading && !error ? data.companies : []), [
    loading,
    error,
    data,
  ]);
};

export const INTERNAL_HIRES_QUERY = gql`
  query InternalHiresQuery(
    $companyID: ID
    $onlyAvailable: Boolean
    $scheduleDate: String
    $enableHoursScheduled: Boolean = false
  ) {
    hires(
      companyID: $companyID
      onlyAvailable: $onlyAvailable
      scheduleDate: $scheduleDate
    ) {
      id
      firstName
      lastName
      companyName
      truckNumber
      licensePlate
      trailerNumber
      phoneNumber
      accountId
      companyId
      available
      roles
      company @include(if: $enableHoursScheduled) {
        driverMaximumScheduledMinutes
        driverMinimumScheduledMinutes
      }
      companyClientPermissionType
      scheduleDate
      minutesScheduled @include(if: $enableHoursScheduled)
      hireTags {
        id
        name
        deleted
      }
      todayDeclinedEvent {
        id
        hireType
        project {
          id
          name
        }
        status
        happenedAt
      }
      externalID
    }
  }
`;

// useHiresInternals returns a tuple containing an array of InternalHires, and the loading state
export const useHiresInternals = (
  companyID = '0',
  onlyAvailable = false,
  selectedDate = '',
  enableHoursScheduled = false,
): [Array<InternalHire>, boolean] => {
  const { selectedCompanyID } = useCompanyContext();
  const { ViewInternalHires } = usePermissions(selectedCompanyID);

  let scheduleDate = '';

  if (selectedDate && selectedDate.format) {
    scheduleDate = selectedDate.startOf('day').format();
  }
  const { loading, error, data } = useQuery(INTERNAL_HIRES_QUERY, {
    variables: {
      companyID,
      onlyAvailable,
      enableHoursScheduled,
      scheduleDate,
    },
    skip: !companyID || companyID === '0' || !ViewInternalHires,
    fetchPolicy: 'cache-and-network',
    refetchWritePolicy: 'overwrite',
  });
  return useMemo(() => {
    // When using the cache-and-network fetchPolicy, it's important to use any
    // data that's currently avaliable/cached, since useQuery will return cached
    // data while loading is true.
    if (data) {
      const hires = [...data.hires];
      return [
        hires
          .sort(
            (a, b) => a.lastName.localeCompare(b.lastName)
              || a.companyName.localeCompare(b.companyName),
          )
          .map(h => ({
            ...h,

            hireTags: h.hireTags.filter(t => !t.deleted),
          })),
        loading,
      ];
    }
    return [[], loading];
  }, [loading, error, data]);
};

export const ADD_INTERNAL_MUTATION = gql`
  mutation createHire($input: HireInput!) {
    createHire(input: $input) {
      id
      firstName
      lastName
      companyName
      truckNumber
      licensePlate
      trailerNumber
      phoneNumber
      accountId
      companyId
      available
      hireTags {
        id
        name
        deleted
      }
      roles
      externalID
    }
  }
`;

export const useAddInternalHire = () => {
  const [addInternal] = useMutation(ADD_INTERNAL_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  return useCallback(
    (values: InternalHire) => addInternal({
      variables: {
        input: {
          ...values,
          // external ID will need to be set as null if it's blank/cleared
          externalID: values.externalID || null,
        },
      },
      refetchQueries: [
        {
          query: INTERNAL_HIRES_QUERY,
          variables: {
            onlyAvailable: false,
            companyID: selectedCompanyID,
            scheduleDate: '',
            enableHoursScheduled: false,
          },
        },
      ],
    }),
    [addInternal, selectedCompanyID],
  );
};

export const EDIT_INTERNAL_MUTATION = gql`
  mutation updateHire($input: HireInput!) {
    updateHire(input: $input) {
      id
      firstName
      lastName
      truckNumber
      licensePlate
      trailerNumber
      phoneNumber
      accountId
      companyId
      available
      hireTags {
        id
        name
        deleted
      }
      roles
      externalID
    }
  }
`;

export const useEditInternalHire = () => {
  const [editInternal] = useMutation(EDIT_INTERNAL_MUTATION);
  return useCallback(
    (values: InternalHire) => editInternal({
      variables: {
        input: {
          ...values,
          // external ID will need to be set as null if it's blank/cleared
          externalID: values.externalID || null,
        },
      },
    }),
    [editInternal],
  );
};

export const DISABLE_INTERNALS_MUTATION = gql`
  mutation disableHires($input: [DisableHireInput!]!) {
    disableHires(input: $input)
  }
`;


export const useRemoveHires = (internalHires: $ReadOnlyArray<InternalHire>) => {
  const [disableInternals] = useMutation(DISABLE_INTERNALS_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  return useCallback(
    (selectedIds: Set<string> | string) => {
      const removedHires = internalHires

        .filter(hire => (typeof selectedIds === 'string'
          ? hire.id === selectedIds
          : selectedIds.has(hire.id)))

        .map(hire => ({
          accountId: hire.accountId,
          companyId: hire.companyId,
        }));
      return disableInternals({
        variables: {
          input: removedHires,
        },
        refetchQueries: [
          {
            query: INTERNAL_HIRES_QUERY,
            variables: {
              onlyAvailable: false,
              companyID: selectedCompanyID,
              scheduleDate: '',
              enableHoursScheduled: false,
            },
          },
        ],
      });
    },
    [internalHires, disableInternals, selectedCompanyID],
  );
};

export const SUBCONTRACTORS_QUERY = gql`
  query SubcontractorsQuery($companyID: ID, $onlyAvailable: Boolean) {
    subcontractors(companyID: $companyID, onlyAvailable: $onlyAvailable) {
      id
      subcontractorID
      name
      priority
      numberHires
      notes
      available
      logo {
        getURL
        filename
      }
      parentCompany {
        id
        name
      }
      companyClient {
        id
        permissions {
          hotDispatch {
            permission
          }
        }
      }
      hireTags {
        id
        name
        deleted
      }
      externalID
    }
  }
`;

// useHiresSubcontractors returns a tuple containing a list of Subcontractors, and the loading state
export const useHiresSubcontractors = (
  companyID = '0',
  onlyAvailable = false,
): [Array<Subcontractor>, boolean] => {
  const { ViewSubcontractors } = usePermissions(companyID);
  const { loading, error, data } = useQuery(SUBCONTRACTORS_QUERY, {
    variables: {
      companyID,
      onlyAvailable,
    },
    skip: !ViewSubcontractors,
  });
  return useMemo(() => {
    if (loading) {
      return [[], true];
    }
    if (data && !loading && !error) {
      const subcontractors = [...data.subcontractors];
      return [
        subcontractors.sort((a, b) => a.name.localeCompare(b.name)),
        false,
      ];
    }
    return [[], false];
  }, [loading, error, data]);
};

export const COMPANY_CLIENT_HIRES_QUERY = gql`
  query CompanyClientHiresQuery(
    $companyID: ID!
    $selectedDate: Time!
    $enableHoursScheduled: Boolean = false
  ) {
    companyClientHires(companyID: $companyID, selectedDate: $selectedDate) {
      id
      firstName
      lastName
      companyName
      truckNumber
      licensePlate
      trailerNumber
      phoneNumber
      accountId
      companyId
      available
      roles
      company @include(if: $enableHoursScheduled) {
        driverMaximumScheduledMinutes
        driverMinimumScheduledMinutes
      }
      companyClientPermissionType
      scheduleDate
      minutesScheduled @include(if: $enableHoursScheduled)
      todayDeclinedEvent {
        id
        happenedAt
        project {
          id
          name
        }
      }
    }
  }
`;

// useCompanyClientHires returns a tuple containing an array of companyClient Hires, and the loading state
export const useCompanyClientHires = (
  companyID = '0',
  enableHoursScheduled = false,
): [Array<InternalHire>, boolean] => {
  const location = useLocation();
  const timezone = useTimezone();
  const { CanHotDispatch: canHotDispatch } = usePermissions(companyID);
  const urlDate = queryString.parse(location.search).date;
  const selectedDate = moment
    .tz(urlDate, timezone)
    .startOf('day')
    .format();

  const { loading, error, data } = useQuery(COMPANY_CLIENT_HIRES_QUERY, {
    variables: {
      companyID,
      selectedDate,
      enableHoursScheduled,
    },
    skip: !companyID || companyID === '0' || !canHotDispatch,
  });
  return useMemo(() => {
    if (loading) {
      return [[], true];
    }
    if (data && !loading && !error) {
      const companyClientHires = [...data.companyClientHires];
      return [
        companyClientHires.sort(
          (a, b) => a.lastName.localeCompare(b.lastName)
            || a.companyName.localeCompare(b.companyName),
        ),
        false,
      ];
    }
    return [[], false];
  }, [loading, error, data]);
};

export const CREATE_SUBCONTRACTOR_MUTATION = gql`
  mutation createSubcontractor($input: SubcontractorInput!) {
    createSubcontractor(input: $input) {
      id
      name
      priority
      numberHires
      notes
      available
    }
  }
`;

export const EDIT_SUBCONTRACTOR_MUTATION = gql`
  mutation updateSubcontractor($id: ID!, $input: SubcontractorInput!) {
    updateSubcontractor(id: $id, input: $input) {
      id
      priority
      numberHires
      notes
      available
      hireTags {
        id
        name
        deleted
      }
      externalID
    }
  }
`;

export const useEditSubcontractor = () => {
  const [editSubcontractor] = useMutation(EDIT_SUBCONTRACTOR_MUTATION);
  return useCallback(
    (values: Subcontractor) => {
      editSubcontractor({
        variables: {
          id: values.id,
          input: {
            ...values,
            // external ID will need to be set as null if it's blank/cleared
            externalID: values.externalID || null,
          },
        },
      });
    },
    [editSubcontractor],
  );
};

export const EDIT_INTERNALS_AVAILABILITY_MUTATION = gql`
  mutation updateHires($input: [HireInput!]!) {
    updateHires(input: $input) {
      id
      available
    }
  }
`;

export const EDIT_SUBCONTRACTORS_AVAILABILITY_MUTATION = gql`
  mutation updateSubcontractors($id: [ID!]!, $input: [SubcontractorInput!]!) {
    updateSubcontractors(id: $id, input: $input) {
      id
      available
    }
  }
`;

export function useSetAvailable<any>(hires: T[]) {
  const [editInternals] = useMutation(EDIT_INTERNALS_AVAILABILITY_MUTATION);
  const [editSubcontractors] = useMutation(
    EDIT_SUBCONTRACTORS_AVAILABILITY_MUTATION,
  );
  const setAvailable = useCallback(
    (hire: InternalHire | Subcontractor, available: boolean) => {
      if (hire.__typename === HireTypes.INTERNAL) {
        return editInternals({
          variables: {
            input: [
              {
                ...hire,
                available,
              },
            ],
          },
        });
      }
      return editSubcontractors({
        variables: {
          id: hire.id,
          input: [
            {
              ...hire,
              parentCompanyId: parseInt(hire.parentCompany.id, 10),
              available,
            },
          ],
        },
      });
    },
    [editInternals, editSubcontractors],
  );

  const toggleHireAvailability = useCallback(
    (selectedIds: Set<string>) => {
      const updatedHires = hires.filter(hire => selectedIds.has(hire.id));

      if (
        !!updatedHires.length
        && updatedHires[0].__typename === HireTypes.INTERNAL
      ) {
        const input = updatedHires.map(h => ({
          ...h,
          available: !h.available,
        }));
        return editInternals({
          variables: {
            input,
          },
        });
      }
      if (
        !!updatedHires.length
        && updatedHires[0].__typename === HireTypes.SUBCONTRACTOR
      ) {
        const id = updatedHires.map(h => h.id);
        const input = updatedHires.map(h => ({
          ...h,
          parentCompanyId: parseInt(h.parentCompany.id, 10),
          available: !h.available,
        }));
        return editSubcontractors({
          variables: {
            id,
            input,
          },
        });
      }
    },
    [hires, editInternals, editSubcontractors],
  );

  return [setAvailable, toggleHireAvailability];
}

export const DISABLE_SUBCONTRACTORS_MUTATION = gql`
  mutation disableSubcontractors($companyID: ID!, $input: [ID!]!) {
    disableSubcontractors(companyID: $companyID, input: $input)
  }
`;

export const useRemoveSubcontractors = () => {
  const [disableSubcontractors] = useMutation(DISABLE_SUBCONTRACTORS_MUTATION);
  const { selectedCompanyID } = useCompanyContext();
  return useCallback(
    (selectedIds: Set<string> | string) => {
      const removedHires = typeof selectedIds === 'string'
        ? [selectedIds]
        : Array.from(selectedIds);
      return disableSubcontractors({
        variables: {
          input: removedHires,
          companyID: selectedCompanyID,
        },
        refetchQueries: [
          {
            query: SUBCONTRACTORS_QUERY,
            variables: { onlyAvailable: false, companyID: selectedCompanyID },
          },
        ],
      });
    },
    [disableSubcontractors, selectedCompanyID],
  );
};

const HIRES_MINUTES_SCHEDULED_QUERY = gql`
  query hiresByCAIDs(
    $input: [HiresByCAIDInput!]!
    $companyID: String!
    $scheduleDate: String
  ) {
    hiresByCAIDs(
      input: $input
      companyID: $companyID
      scheduleDate: $scheduleDate
    ) {
      id
      accountId
      scheduleDate
      minutesScheduled
      company {
        driverMaximumScheduledMinutes
        driverMinimumScheduledMinutes
      }
    }
  }
`;

// useHiresMinutesScheduled is a lazy query that only executes when you call the function
// it returns. This is used for refreshing any hire scheduled duration information on
// updating assignments
export const useHiresMinutesScheduledQuery = () => {
  const { selectedCompanyID: companyID } = useCompanyContext();
  const [
    fetchHires,
    { loading, error, data },
  ] = useLazyQuery(HIRES_MINUTES_SCHEDULED_QUERY, {
    fetchPolicy: 'network-only',
  });

  const getHiresByCAIDs = (input = [], scheduleDate = '') => fetchHires({
    variables: {
      input,
      companyID,
      scheduleDate,
    },
  });

  return useMemo(() => {
    if (loading) {
      return [getHiresByCAIDs, []];
    }
    if (data && !loading && !error) {
      return [getHiresByCAIDs, data.hiresByCAIDs];
    }
    return [getHiresByCAIDs, []];
  }, [loading, error, data, getHiresByCAIDs]);
};
